import React, { Component } from 'react';
import Nav from '../../components/Nav/Nav'
import './PressView.css';

export default class PressView extends Component {

constructor(props, context) {
    super(props, context);

    this.state = {
     images: []
    }
  }


getImages(){

  const instagramId = '1450422389';
  const access_token = '1450422389.620a2fd.3b70bff48ed84f7aba066b1ac5a30ff9';
  const url = 'https://api.instagram.com/v1/users/' +  instagramId + '/media/recent/?access_token=' + access_token;

      fetch(url, {
        method: 'GET',
        headers: {
        }
        })
        .then((response) => response.json())
        .then((responseJson) =>
        {
          console.log(responseJson);
          this.setState({
            images: responseJson.data
          })
          

        }).catch((error) => {
        console.log("Error");
        console.error(error);
      });
}

  componentDidMount(){
     
  }


  /*---------------------------------------
      RENDER
  -----------------------------------------*/
  render() {

    return (
      <div>
      <Nav />
      <div className="wrapper">
      <div className="content">
        <div className="photos">
         {
              this.state.images.map((image, index) =>{
                  return(
                      <div key={index} className="image"><img src={image.images.thumbnail.url}/></div>
                    )
                })
        }
        </div>
      </div>
      </div>
      </div>

    );
  }

}