import React, { Component } from 'react';
// import logo from './logo.svg';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import Nav from './components/Nav/Nav'
/*---------------------------------------
    VIEWS           
-----------------------------------------*/
import HomeView from './views/HomeView/HomeView.js';
import PressView from './views/PressView/PressView.js';
import DownloadView from './views/DownloadView/DownloadView.js';
/*---------------------------------------
    APP BEGIN           
-----------------------------------------*/
const App = () => (

  <Router>

  <div>
      <Route exact path="/" component={Home} />
      <Route exact path="/downloads" component={Download} />
      <Route exact path="/press" component={Press} />
  </div>

  </Router>

);


const Home = () => (
    <HomeView />
);

const Download = () => (
    <DownloadView/>
);

const Press = () => (
    <PressView/>
);



export default App;
