import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import './Nav.css';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as Menu } from '../../images/menu.svg';

export default class Nav extends Component {

constructor(props, context) {
    super(props, context);

    this.state = {
     active: false
    }

    this.toggleClass = this.toggleClass.bind(this);
  }

  componentDidMount(){
     
  }

toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };


  /*---------------------------------------
      RENDER
  -----------------------------------------*/
  render() {

    return (
      <nav className={this.state.active ? 'navOpen': ''} >
      <div className='logo'  onClick={this.toggleClass}>
        <NavLink to="/" className="noHover"><Logo/></NavLink>
        <Menu className={'menu' + (this.state.active ? ' menuOpen': '')} ></Menu>
      </div>

      <div className="links">
        <NavLink to="/downloads" activeClassName="activeLink">Downloads</NavLink>
        <a href="http://facebook.com/kodiakgalaxy" target="_blank">Contact</a>
      </div>
      </nav>

    );
  }

}