import React, { Component } from 'react';
import './HomeView.css';
import Nav from '../../components/Nav/Nav'

export default class HomeView extends Component {

constructor(props, context) {
    super(props, context);

    this.state = {
     
    }
  }

  componentDidMount(){
     
  }

  /*---------------------------------------
      RENDER
  -----------------------------------------*/
  render() {

    return (
      
      <div className="homeBackground">
      <Nav />
      <div className="infoBox">
        <h1>A Bad Time for Dreamers</h1>
        <p>The album - coming in 2019</p>
      </div>
      </div>

    );
  }

}