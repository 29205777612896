import React, { Component } from 'react';
import './DownloadView.css';
import '../../css/forms.css';
import Nav from '../../components/Nav/Nav'

export default class DownloadView extends Component {

constructor(props, context) {
    super(props, context);

    this.state = {
      alertMessage: '',
      processing: '',
      email: '',
      code: '',
      signup: false,
      active: false,
      apiUrl: '',
    }

    this.redeemCode = this.redeemCode.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.toggleClass = this.toggleClass.bind(this);
    this.handleSignUpChange = this.handleSignUpChange.bind(this);


  }


  componentDidMount(){

     const hostname = window.location.hostname;
     if(hostname == 'localhost')
     {
      this.setState({
        apiUrl: process.env.REACT_APP_APIURLLOCAL
      })
     }else{      
      this.setState({
        apiUrl: process.env.REACT_APP_APIURLLIVE
      })
     }
     
  }




  /*---------------------------------------
      REDEEM CODE           
  -----------------------------------------*/
    redeemCode(event){


      event.preventDefault();
    
      this.setState({
        processing: true,
        alertMessage: ''
      })



      if(this.state.email.length>0 && this.state.code){

      const url = this.state.apiUrl + '/getFile';
        

      fetch(url, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: this.state.code,
          email: this.state.email,
          signup: this.state.signup
          })
        })
        .then((response) => response.json())
        .then((responseJson) =>
        {
          setTimeout(()=>{

              if(responseJson.link){
                window.location.href = responseJson.link;
                this.setState({
                  alertMessage: '',
                  processing: ''
                })
              }else{
                this.setState({
                 alertMessage: responseJson.message,
                 processing: ''
                })
              }
          },1000)
   
        

          

        }).catch((error) => {
        console.log("Error");
        console.error(error);
        this.setState({
            alertMessage: "Something went wrong :( . We'll look into it.",
            processing: ''
        })
      });


      }
      
      

    }

  handleEmailChange(event){
    this.setState({
      email: event.target.value,
      alertMessage: ''
    });
  }

  handleCodeChange(event){
    this.setState({
      code: event.target.value,
      alertMessage: ''
    });
  }

  handleSignUpChange(event)
  {
    this.setState({
      signup: event.target.checked
    });
    
  }

  toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };

  /*---------------------------------------
      RENDER
  -----------------------------------------*/
  render() {

    return (
      <div>

      <Nav />

      <div className="wrapper">
          <div className="content">
            <form onSubmit={this.redeemCode}>
              <small className="alertMessage">{this.state.alertMessage}</small>
              <label>Email</label>
              <input name="email" type="email" required placeholder="Your email address" value={this.state.email} onChange={this.handleEmailChange}/>
              <label>Download Code</label>
              <input name="code" type="text" required placeholder="8 character code" value={this.state.code} onChange={this.handleCodeChange}/>
              <div className="row checkBox">
                <div className={'checkContainer ' + (this.state.active ? 'checked': null)} onClick={this.toggleClass}  ><input type="checkbox" onChange={this.handleSignUpChange}/></div>
                <label>Subscribe to our mailing list</label>
              </div>
              <button disabled={this.state.processing} data-text="Download"></button>
            </form>
          </div>

        </div>


      </div>

    );
  }

}